var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Leads por canal"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.currentMonthFilters.leads},
                {category: 'prevMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.prevMonthFilters.leads},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: currentMonthFilters.leads},\n                {category: 'prevMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: prevMonthFilters.leads},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.leads,"prev-month-records":_vm.chartData.prevMonth.leads,"path-to-category":['channel'],"path-to-date":['createdAt'],"dates":_vm.dates,"data-transformer":_vm.colorChannels,"vertical-legend":true,"category-name":"canal"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Leads por canal último año operativo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'lastYear', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.lastYearFilters.leads})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'lastYear', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: lastYearFilters.leads})\n            }"}],attrs:{"records":_vm.chartData.lastYear.leads,"prev-month-records":[],"path-to-category":['channel'],"path-to-date":['createdAt'],"monthly":true,"dates":_vm.lastYearDates,"data-transformer":_vm.colorChannels,"vertical-legend":true,"total-sufix":"prom./mes","label-sufix":"prom./mes","category-name":"canal","legend-header":"prom./mes","display-means":false}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Leads por negocio"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.currentMonthFilters.leads},
                {category: 'prevMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.prevMonthFilters.leads},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: currentMonthFilters.leads},\n                {category: 'prevMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: prevMonthFilters.leads},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.leads,"prev-month-records":_vm.chartData.prevMonth.leads,"path-to-category":['pipeline'],"path-to-date":['createdAt'],"show-prev-month":true,"show-totals":false,"dates":_vm.dates,"vertical-legend":true}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Leads por negocio último año operativo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'lastYear', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.lastYearFilters.leads})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'lastYear', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: lastYearFilters.leads})\n            }"}],attrs:{"records":_vm.chartData.lastYear.leads,"prev-month-records":[],"path-to-category":['pipeline'],"path-to-date":['createdAt'],"show-prev-month":true,"show-totals":false,"monthly":true,"dates":_vm.lastYearDates,"vertical-legend":true,"total-sufix":"prom./mes","label-sufix":"prom./mes","legend-header":"prom./mes"}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Estado de leads"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('leads-by-task',{attrs:{"filter":_vm.currentMonthFilters.leadsTask,"show-title":false,"title":"Estado de leads","error-title":"Sin leads"}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Leads por canal"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.currentMonthFilters.leads})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: currentMonthFilters.leads})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.leads,"path-to-category":['channel'],"small-chart":true,"path-to-subcategory":['pipeline'],"category-name":"canal","data-transformer":_vm.colorChannels}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Leads por derivador"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.currentMonthFilters.leads})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: currentMonthFilters.leads})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.leads,"small-chart":true,"path-to-subcategory":['channel'],"category-name":"derivador","criterias":[
              {
                name: 'GENIO',
                color: '#6185DB',
                filter: (record) => record.createdBy?.['x-hasura-user'] === 'system' && !record.idForwarder
              },
              {
                name: 'Manual',
                color: '#EFDE72',
                filter: (record) => record.idForwarder
              },
              {
                name: 'Prospectado',
                color: '#9591B5',
                filter: (record) => record.createdBy?.['x-hasura-user-email'] === record.executive?.person?.user?.email && !record.idForwarder
              },
              {
                name: 'Sin ejecutivo',
                color: '#737373',
                filter: (record) => !record.executive
              },
            ]}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Leads por negocio"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.currentMonthFilters.leads})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: currentMonthFilters.leads})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.leads,"path-to-category":['pipeline'],"small-chart":true,"path-to-subcategory":['channel']}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{staticClass:"pa-4",attrs:{"title":"Leads por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('lead-by-executive',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'leads', queryMethod: _vm.getLeadsAggregate, queryFilter: _vm.currentMonthFilters.leads})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'leads', queryMethod: getLeadsAggregate, queryFilter: currentMonthFilters.leads})\n            }"}],attrs:{"leads":_vm.chartData.currentMonth.leads,"executives":_vm.chartData.settings.executives}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }